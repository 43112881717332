<template>
  <div class="" style="margin-bottom: 50px">
    <div class="accordion" id="einzel_accordionExample">
      <div class="d-none d-sm-block">
        <div class="steps">
          <progress
            id="progress"
            :value="[
              step == 1
                ? '25'
                : [step == 2 ? '50' : [step == 3 ? '75' : '100']],
            ]"
            max="100"
          ></progress>
          <div class="step-item">
            <button
              class="step-button text-center"
              type="button"
              aria-expanded="true"
            >
              1
            </button>
            <div class="step-title">LP 1</div>
          </div>
          <div class="step-item">
            <button
              class="step-button text-center"
              :class="[step == 2 ? '' : 'collapsed']"
              type="button"
              :aria-expanded="[step > 1 ? 'true' : 'false']"
            >
              2
            </button>
            <div class="step-title">wählen</div>
          </div>
          <div class="step-item">
            <button
              class="step-button text-center"
              :class="[step == 3 ? '' : 'collapsed']"
              type="button"
              :aria-expanded="[step > 2 ? 'true' : 'false']"
            >
              3
            </button>
            <div class="step-title">LP 2</div>
          </div>
          <div class="step-item">
            <button
              class="step-button text-center"
              :class="[step > 3 ? '' : 'collapsed']"
              type="button"
              :aria-expanded="[step > 2 ? 'true' : 'false']"
            >
              4
            </button>
            <div class="step-title">wählen</div>
          </div>
        </div>
      </div>
      <div style="display: none">{{ scanncode }}</div>
      <div class="card1" v-if="step == '1'">
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
        >
          <div class="card-body" style="padding: 0">
            <div class="card">
              <div class="card-header">Wähle den Lagerplatz aus oder Scanne..</div>
              <div class="card-body">
                <div class="d-flex justify-content-around">
                  <div class="col-12 col-md-11">
                    <Form
                      @submit="submitData"
                      :validation-schema="schema"
                      v-slot="{ errors }"
                      v-if="!success"
                    >
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">
                          <font-awesome-icon
                            :icon="['fas', 'search']"
                            size="2xl"
                            v-if="!isLoading"
                          />

                          <font-awesome-icon
                            :icon="['fas', 'star-of-life']"
                            size="2xl"
                            spin
                            v-if="isLoading"
                          />
                        </span>
                        <Field
                          as="input"
                          name="barcode"
                          type="text"
                          class="form-control"
                          id="barcode"
                          ref="barcode"
                          autocomplete="given-name"
                          placeholder="Lagerplatz"
                          aria-label="Lagerplatz"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <small class="text-danger" v-if="errors.barcode">{{
                        errors.barcode
                      }}</small>

                      <div v-if="input != '' && !isLoading">
                        <div class="list-group">
                          <a
                            v-for="eintrag in suchenliste"
                            :key="eintrag.lagerplatzid"
                            href="#"
                            @click="setLagerplatz(eintrag.lagerplatzid)"
                            class="list-group-item list-group-item-action d-flex justify-content-between align-items-start px-0"
                          >
                            <div class="container">
                              <div class="row">
                                <div class="col px-2">
                                  <span class="fw-bold">
                                    {{ eintrag.kuerzel }}
                                  </span>
                                  <span
                                    class="badge bg-success"
                                    style="float: right"
                                    >{{ eintrag.lager }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>

                <div v-if="input == ''">
                  <div class="m-1">
                    <h6 class="card-title">Logistik</h6>
                  </div>
                </div>

                <div v-if="input == ''">
                  <div class="card m-1 bg-success-logistik text-white">
                    <div class="card-body py-2">
                      <a class="stretched-link"></a>
                      <h6 class="card-title">Logistik</h6>
                      <h4 class="card-text">Ware umlagern</h4>
                    </div>
                  </div>
                </div>

                <div class="d-flex justify-content-center">
                  <img
                    v-if="input == ''"
                    class="logo my-3"
                    style="width: 96%; max-width: 130px"
                    src="@/assets/img/Baho_Markenzeichen.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card1" v-if="step == '2'">
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
        >
          <div class="card-body" style="padding: 0">
            <div class="card">
              <div class="card-header" v-if="lpdaten.txt">
                Aktueller Lagerplatz: {{ lpdaten.txt }}<br />
                Wähle die Ware aus:
              </div>
              <div class="card-header" v-if="!produktdaten[0]">
                Aktuell ist nichts auf dem Lagerplatz.
              </div>
              <div>
                <div v-if="produktdaten.length > 0">
                  <div
                    class="card m-1"
                    v-for="bestandsliste in produktdaten"
                    :key="bestandsliste.id"
                    @click="setProduktbestandid(bestandsliste.id)"
                  >
                    <div class="card-body py-2">
                      <a class="stretched-link"></a>
                      <h4 class="card-title">
                        {{ bestandsliste.name }}
                        <span class="badge bg-success" style="float: right" v-if="bestandsliste.charge!=''"
                          >CH: {{ bestandsliste.charge }}</span
                        >
                      </h4>
                      <h6 class="card-text">
                        {{ bestandsliste.stueck }}
                        {{ bestandsliste.stueckname }}
                        <span class="badge bg-info" style="float: right">
                          LD:
                          {{
                            datum_formatieren(bestandsliste.lieferdatum)
                          }}</span
                        >
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card1" v-if="step == '3'">
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
        >
          <div class="card-body" style="padding: 0">
            <div class="card">
              <div class="card-header" v-if="!input2">
                Wähle den Ziel Lagerplatz aus oder Scanne..
              </div>
              <div class="card-body">
                <div class="d-flex justify-content-around">
                  <div class="col-12 col-md-11">
                    <Form
                      @submit="submitData2"
                      :validation-schema="schema2"
                      v-slot="{ errors }"
                      v-if="!success"
                    >
                      <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-addon1">
                          <font-awesome-icon
                            :icon="['fas', 'search']"
                            size="2xl"
                            v-if="!isLoading"
                          />

                          <font-awesome-icon
                            :icon="['fas', 'star-of-life']"
                            size="2xl"
                            spin
                            v-if="isLoading"
                          />
                        </span>
                        <Field
                          as="input"
                          name="barcode2"
                          type="text"
                          class="form-control"
                          id="barcode2"
                          ref="barcode2"
                          autocomplete="given-name"
                          placeholder="Lagerplatz"
                          aria-label="Lagerplatz"
                          aria-describedby="basic-addon1"
                        />
                      </div>
                      <small class="text-danger" v-if="errors.barcode2">{{
                        errors.barcode2
                      }}</small>

                      <div v-if="suchenliste2 != '' && !isLoading">
                        <div class="list-group">
                          <a
                            v-for="eintrag in suchenliste2"
                            :key="eintrag.lagerplatzid"
                            href="#"
                            @click="setLagerplatz(eintrag.lagerplatzid)"
                            class="list-group-item list-group-item-action d-flex justify-content-between align-items-start px-0"
                          >
                            <div class="container">
                              <div class="row">
                                <div class="col px-2">
                                  <span class="fw-bold">
                                    {{ eintrag.kuerzel }}
                                  </span>
                                  <span
                                    class="badge bg-success"
                                    style="float: right"
                                    >{{ eintrag.lager }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card1" v-if="step == '4'">
        <div
          id="collapseOne"
          class="collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#einzel_accordionExample"
        >
          <div class="card-body" style="padding: 0" v-if="produktdatenbestand && produktdatenbestand.length>0 && lpdaten.txt!=produktdatenbestand[0].txt">
            <div class="card">
              <div class="card-header" v-if="lpdaten.txt">
                Neuer Lagerplatz: {{ lpdaten.txt }}<br />
                Aktuell belegt mit:
              </div>
              <div>
                <div class="card-header" v-if="!produktdaten[0]">
                Aktuell ist nichts auf dem Lagerplatz.
              </div>
                <div v-if="produktdaten.length > 0">
                  <div
                    class="card m-1"
                    v-for="bestandsliste in produktdaten"
                    :key="bestandsliste.id"
                  >
                    <div class="card-body py-2">
                      <a class="stretched-link"></a>
                      <h4 class="card-title">
                        {{ bestandsliste.name }}
                        <span class="badge bg-success" style="float: right" v-if="bestandsliste.charge!=''"
                          >CH: {{ bestandsliste.charge }}</span
                        >
                      </h4>
                      <h6 class="card-text">
                        {{ bestandsliste.stueck }}
                        {{ bestandsliste.stueckname }}
                        <span class="badge bg-info" style="float: right">
                          LD:
                          {{
                            datum_formatieren(bestandsliste.lieferdatum)
                          }}</span
                        >
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div class="card-body" style="padding: 0"  v-if="produktdatenbestand && produktdatenbestand.length>0 && lpdaten.txt!=produktdatenbestand[0].txt">
            <div class="card">
              <div class="card-header">Neuer zusätzliche Belegung mit:</div>
              <div>
                <div v-if="produktdatenbestand.length > 0">
                  <div
                    class="card m-1"
                    v-for="bestandsliste in produktdatenbestand"
                    :key="bestandsliste.id"
                  >
                    <div class="card-body py-2">
                      <a class="stretched-link"></a>
                      <h4 class="card-title">
                        {{ bestandsliste.name }}
                        <span class="badge bg-success" style="float: right"  v-if="bestandsliste.charge!=''">CH: {{ bestandsliste.charge }}</span
                        >
                      </h4>
                      <h6 class="card-text">
                        {{ bestandsliste.stueck }}
                        {{ bestandsliste.stueckname }}
                        <span class="badge bg-info" style="float: right">
                          LD:
                          {{
                            datum_formatieren(bestandsliste.lieferdatum)
                          }}</span
                        >
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <b-button variant="success" @click="pbidUmlagern()"  v-if="!isLoading && produktdatenbestand && produktdatenbestand.length>0 && lpdaten.txt!=produktdatenbestand[0].txt"
            >Ware jetzt umlagern</b-button
          >
          <div  v-if="produktdatenbestand && produktdatenbestand.length>0 && lpdaten.txt==produktdatenbestand[0].txt" class="alert alert-danger text-center">
            <h2 class="py-4">Der neue kann nicht der Alte Lagerplatz sein, gehe zurück!</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <nav class="navbar fixed-bottom navbar-light bg-light justify-content-end">
    <div class="col-12 col-lg-9">
      <div class="d-flex justify-content-around">
        <b-button @click="step--" :disabled="step < 2" style="width: 110px"
          ><font-awesome-icon :icon="['fass', 'forward']" rotation="180" />
          zurück</b-button
        >
        <b-button
          variant="danger"
          v-on:click="abbrechenZeigen()"
          style="width: 110px"
          >abbrechen</b-button
        >
        <b-button
          variant="success"
          @click="step++"
          :disabled="true"
          style="width: 110px"
          >weiter <font-awesome-icon :icon="['fass', 'forward']"
        /></b-button>
      </div>
    </div>
  </nav>
</template>

<script>
//import { mapGetters } from "vuex";
import Store from "@/store";
import { Form, Field } from "vee-validate";
import * as yup from "yup";
import { uuid } from "vue-uuid";
import axios from "axios";
import dayjs from "dayjs";
//import { useSound } from "@vueuse/sound";
//import soundError from "@/assets/sounds/error.mp3";

//import { log } from "logrocket";
import { useToast, POSITION } from "vue-toastification";

export default {
  name: "TheLogistikUmlagern",
  components: {
    Form,
    Field,
    //...mapGetters({ cartSum: "cartTotal" }), // wenn auf andere Variable übergeben wird
  },
  data() {
    const schema = yup.object().shape({
      barcode: yup
        .string()
        .required("Ein Barcode/Name wird benötigt.")
        .trim()
        .min(3, "Der Barcode/Name muss >3 Zeichen lang sein!"),
    });
    const schema2 = yup.object().shape({
      barcode2: yup
        .string()
        .required("Ein Barcode/Name wird benötigt.")
        .trim()
        .min(3, "Der Barcode/Name muss >3 Zeichen lang sein!"),
    });
    return {
      step: 1,
      schema,
      schema2,
      error: "",
      isLoading: false,
      success: false,
      isOnLine: true, //@TODO: direkt holen

      invitee: "",
      mode: "",
      input: "",
      input2: "",
      suchenliste: [],
      suchenliste2: [],

      produktdaten: [],
      produktdatenbestand: [],
      lpdaten: [],
      produktbestandid: "",
      lagerplatzid: "",

      toast: useToast(),
    };
  },
  props: {
    search: {
      type: String,
      default: "",
    },
  },
  computed: {
    rrID() {
      return Store.getters.activeRREvent();
    },

    searchData() {
      return this.search;
    },

    scanncode() {
      console.log("scanncodeaufruf....");
      var scanncode_akt = Store.getters.activeBarcode();
      Store.mutations.setBarcode([]);
      if (scanncode_akt.org) {
        // Manuelle Sucheliste leeren
        console.log("GP scanncode aufruf!", scanncode_akt, this.step);
        if (this.step == 1) {
          if (scanncode_akt.type == "l") {
            this.holeEinlagerungen(scanncode_akt.org.split("/")[1]);
          } else {
            // Barcode falsch
            this.toast.error("Barcode an dieser Stelle nicht möglich!", {
              position: POSITION.TOP_CENTER,
            });
            //Store.mutations.setBarcode([]);
            scanncode_akt = [];
          }
        } else if (this.step == 3) {
          if (scanncode_akt.type == "l") {
            this.setLagerplatz(scanncode_akt.org.split("/")[1]);
          } else {
            // Barcode falsch
            this.toast.error("Barcode an dieser Stelle nicht möglich!", {
              position: POSITION.TOP_CENTER,
            });
            //Store.mutations.setBarcode([]);
            scanncode_akt = [];
          }
        }


        

      }
      return scanncode_akt;
    },
  },

  methods: {
    datum_formatieren(x) {
      return dayjs(x * 1000).format("DD.MM.YY");
    },
    nummerFormatieren(x, stellen = 0) {
      return Number.parseFloat(x).toFixed(stellen);
    },
    holeEinlagerungen(nr) {
      this.isLoading = true;
      this.lagerplatzid = nr;
      axios
        .get(
          "https://bahnhof-logistik.pani-verwaltung.de/module/api_logistik.php" +
            "?lpid=" +
            nr +
            "&user=" +
            Store.getters.activeGeraet() +
            "&uuid=" +
            uuid.v1()
        )
        .then((response) => {
          console.log("Rückgabe",response.data.status);

          this.isLoading = false;

          if (response.data.status == "0") {
            console.log("fertig geholt...");
            this.produktdaten = response.data.data;
            this.lpdaten = response.data.data_lp;
            
            this.step++;
            console.log("Response Data", response.data);
            console.log("Prdouktdaten", this.produktdaten);
          }
          return false;
        })
        .catch((error) => {
          this.isLoading = false;
          throw new Error(error);
        });
    },

    holeProduktbestandID(nr) {
      this.isLoading = true;
      axios
        .get(
          "https://bahnhof-logistik.pani-verwaltung.de/module/api_logistik.php" +
            "?pbid=" +
            nr +
            "&user=" +
            Store.getters.activeGeraet() +
            "&uuid=" +
            uuid.v1()
        )
        .then((response) => {
          console.log(response.data.status);

          this.isLoading = false;

          if (response.data.status == "0") {
            console.log("fertig geholt...");
            this.produktdatenbestand = response.data.data;
            console.log("Response Data", response.data);
            console.log("Prdouktdaten", this.produktdaten);
          }
          return false;
        })
        .catch((error) => {
          this.isLoading = false;
          throw new Error(error);
        });
    },

    pbidUmlagern() {
      this.isLoading = true;
      axios
        .get(
          "https://bahnhof-logistik.pani-verwaltung.de/module/api_logistik.php" +
            "?pbid=" +
            this.produktbestandid +
            "&lpid=" +
            this.lagerplatzid +
            "&user=" +
            Store.getters.activeGeraet() +
            "&uuid=" +
            uuid.v1()
        )
        .then((response) => {
          console.log("pbidUmlagern Rückgabe",response.data);
          this.isLoading = false;
          
          if (response.data.status == "0") {
            console.log("fertig umgelagert...");
            this.produktdaten= [];
            this.produktdatenbestand= [];
            this.lpdaten= [];
            this.produktbestandid= "";
            this.lagerplatzid= "";
            this.step = 1;
            //this.toast.success("Lagerplatz geändert!", {
            //  position: POSITION.TOP_CENTER,
            //});
          }
        })
        .catch((error) => {
          this.isLoading = false;
          this.produktdaten= [];
          this.produktdatenbestand= [];
          this.lpdaten= [];
          this.produktbestandid= "";
          this.lagerplatzid= "";
          this.step = 1;
          this.toast.warning("Eventuell Fehlerhaft?");
          throw new Error(error);
        });
    },

    suchlisteLeeren() {
      // Manuelle Sucheliste leeren
      console.log("suchlisteLeeren");
      this.input = "";
      this.suchenliste = [];
    },

    holeLagerplaetze(returnvar) {
      console.log("holeLagerplaetze");
      if (this.isOnLine) {
        axios
          .get(
            "https://bahnhof-logistik.pani-verwaltung.de/module/api_logistik.php" +
              "?lpname=" +
              this.input.trim() +
              "&user=" +
            Store.getters.activeGeraet() +
              "&uuid=" +
              uuid.v1() +
              { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            console.log(response.data);

            this.isLoading = false;
            if (returnvar == "suchenliste") {
              this.suchenliste = response.data;
              console.log(this.suchenliste);
            }
            if (returnvar == "suchenliste2") {
              this.suchenliste2 = response.data;
              console.log(this.suchenliste2);
            }
            return response.data;
          })
          .catch((error) => {
            this.isLoading = false;
            throw new Error(error);
          });
      } else {
        this.isLoading = false;
        return false;
      }
    },
    submitData(values) {
      this.isLoading = true;
      this.success = false;
      this.error = "";
      console.log(values);
      this.input = values.barcode;
      //this.invitee = uuid.v1();
      this.holeLagerplaetze("suchenliste");
    },
    submitData2(values) {
      this.isLoading = true;
      this.success = false;
      this.error = "";
      console.log(values);
      this.input = values.barcode2;
      //this.invitee = uuid.v1();
      this.holeLagerplaetze("suchenliste2");
    },
    focusInput() {
      if (this.searchData) {
        this.$refs.barcode.$el.value = this.searchData;
        this.input = this.searchData;
        this.holePersonen();
      } else {
        this.$refs.barcode.$el.focus();
      }
    },
    modusUpdate(event) {
      console.log(event);
      this.$emit("modus-updated", event);
      this.mode = event;
    },
    setLagerplatz(id) {
      this.barcode = id;
      this.laden = console.log("setBarcode ", this.barcode);
      this.holeEinlagerungen(id);
    },
    setProduktbestandid(id) {
      this.produktbestandid = id;
      console.log("setProduktbestandid ", this.produktbestandid);
      this.step = 3;
      this.holeProduktbestandID(id);
    },
    abbrechenZeigen() {
      //if (this.sammelTLNs.length > 0) {
      //  this.listeAbbrechen = true;
      window.scrollTo(0, 0);
      //} else {
      if (this.step > 1) {
        this.step--;
        console.log("abbrechenZeigen step--");
        //Store.mutations.deleteAlleSammelTLNs();
      } else if (this.step > 0) {
        this.modusUpdate({ newModus: "0" });
      }

      //}
    },
  },
  emits: {
    "modus-updated": (modusDO) => {
      if ("newModus" in modusDO === false) {
        console.warn(
          "ModusCardComponent: Jede Button muss ein Modus-Attribut haben."
        );
        return false;
      }
      return true;
    },
  },
  mounted() {
    //this.focusInput();
  },
};
</script>

<style scoped>
.bg-success-logistik {
  background-color: rgba(45 104 48);
}
</style>
