<template>
  <nav class="navbar navbar-expand-lg navbar-dark fixed-top" :class="bgNavbar">
    <div class="container-fluid">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarTogglerDemo"
        aria-controls="navbarTogglerDemo"
        aria-expanded="false"
        aria-label="Toggle navigation"
        @click="timeoutHide()"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <a class="navbar-brand" href="#">
        Logistik-App
        <img
          src="@/assets/img/Haus_reduziert.svg"
          alt=""
          width="24"
          height="24"
          class="d-inline-block align-text-top ms-1"
          style="filter: brightness(0) invert(1)"
        />
      </a>
      <button
        type="button"
        class="btn btn-light"
        data-bs-toggle="offcanvas"
        href="#offcanvasExample"
        aria-controls="offcanvasExample"
        style="float: right"
      >
        <font-awesome-icon :icon="['fas', 'user']" />
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbarTogglerDemo"
        :class="!visible ? 'collapse' : ''"
      >
        <ul class="navbar-nav ms-auto">
          <li class="nav-item" v-if="activeMode.includes('Startkontrolle')">
            <a
              class="nav-link"
              href="#kontrolle"
              @click="setView('Startkontrolle')"
              style="font-size: 120%"
              >Logistik</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import Store from "@/store";

import { useToast, POSITION } from "vue-toastification";
//import { mapGetters } from "vuex";

export default {
  name: "TheNavbar",
  data() {
    return {
      visible: false,
      isOnLine: navigator.onLine,

      toast: useToast(),
    };
  },
  computed: {
    //...mapGetters({ cartSum: "cartTotal" }), // wenn auf andere Variable übergeben wird
    activeMode() {
      return Store.getters.activeMode();
    },
    bgNavbar() {
      if (this.isOnLine) {
        return "bg-dark";
      }
      return "bg-danger";
    },
  },
  methods: {
    setView(view) {
      this.visible = !this.visible;
      Store.mutations.setActiveView(view);
      this.$emit("modus-updated", { newModus: "0" });
    },
    async signout() {
      try {
        await this.$store.dispatch("signout");
        this.$router.push("/");
      } catch (error) {
        console.log(error);
      }
    },
    timeoutHide() {
      setTimeout(() => {
        this.visible = !this.visible;
      }, 5000);
    },
  },

  mounted() {
    window.addEventListener("online", () => {
      this.isOnLine = true;
      Store.mutations.setOnline(this.isOnLine);
      this.toast.success("Gerät ist wieder online!", {
        position: POSITION.TOP_CENTER,
      });
    });
    window.addEventListener("offline", () => {
      this.isOnLine = false;
      Store.mutations.setOnline(this.isOnLine);
      this.toast.error("Gerät ist offline!", {
        position: POSITION.TOP_CENTER,
      });
    });
  },
};
</script>

<style scoped>
.bg_dark {
  background-color: rgba(45 104 48) !important;
}
</style>
